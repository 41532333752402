import { useState } from 'react'
import install from '../../../content/install'
import MainTitle from '../../MainTitle/MainTitle'
import MarkdownText from '../../MarkdownText/MarkdownText'
import PennyLaneVersion from '../../PennyLaneVersion/PennyLaneVersion'
import TabbedCode from '../../TabbedCode/TabbedCode'
import { ITabCombination } from '../HighPerformanceSection/HighPerformanceSection'
import './style.scss'
import { StaticImage } from 'gatsby-plugin-image'

const { hero } = install
const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_transparent.png`

const InstallHero = () => {
  const [selectedTabCombination, setSelectedTabCombination] =
    useState<ITabCombination>(hero.tabs.combinations[0])

  return (
    <section
      className="InstallHero Hero__Background__Container"
      data-testid="install-hero"
    >
      <StaticImage
        src={HERO_BACKGROUND}
        alt=""
        placeholder="none"
        quality={100}
        objectPosition="top left"
        className="Hero__Background__Image Sticky__Image"
      />
      <div className="InstallHero__inner-container">
        <div>
          <MainTitle title={install.hero.title} addClass="text-[#232323]" />
          <h2 className="Install__Subtitle" data-testid="install-hero-subtitle">
            <MarkdownText text={install.hero.sub_title} />
          </h2>
          <PennyLaneVersion />
        </div>
        <div className="max-w-[310px] sm:max-w-[522px] sm:min-w-[522px]">
          <TabbedCode
            tabs={hero.tabs}
            images={{
              bottom_left_image: install.hero.corner_images.bottom_left_image,
              top_right_image: install.hero.corner_images.top_right_image,
            }}
            bottom_link={install.hero.bottom_link}
            selectedTabCombination={selectedTabCombination}
            setSelectedTabCombination={setSelectedTabCombination}
          />
        </div>
      </div>
    </section>
  )
}

export default InstallHero
