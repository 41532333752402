import { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import LinkComponent from '../LinkComponent/LinkComponent'
import { get } from 'lodash'

interface IPLVersion {
  slug?: string
  title?: string
}

const PennyLaneVersion = () => {
  const [PLVersion, setPLVersion] = useState<IPLVersion>({
    slug: '',
    title: '',
  })
  const blogData = useStaticQuery<Queries.GetRecentReleaseBlogQuery>(blogQuery)
  const { nodes } = blogData.allMarkdownRemark

  useEffect(() => {
    setPLVersion({
      slug: get(nodes?.[0], 'fields.slug', ''),
      title: get(nodes?.[0], 'frontmatter.title', ''),
    })
  }, [])

  return PLVersion.slug && PLVersion.title ? (
    <LinkComponent
      href={`/blog${PLVersion.slug}`}
      addClass="PennylaneRelease__link"
      testId="pennylane-release-link"
    >
      {PLVersion.title} <i className="bx bx-link-external"></i>
    </LinkComponent>
  ) : null
}

export default PennyLaneVersion

export const blogQuery = graphql`
  query GetRecentReleaseBlog {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { tags: { in: "RELEASE" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          tags
        }
      }
    }
  }
`
