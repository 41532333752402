import Image from '../Image/Image'
import './TabbedCode.scss'
import LinkComponent from '../LinkComponent/LinkComponent'
import {
  ITabCombination,
  ITabs,
} from '../InstallPage/HighPerformanceSection/HighPerformanceSection'
import { Dispatch, SetStateAction } from 'react'
import { detectDisabledTabs } from '../../utils/tab_helpers'
import CodeBox from './CodeBox/CodeBox'

interface ITabbedCodeProps {
  tabs: ITabs
  selectedTabCombination: ITabCombination
  setSelectedTabCombination: Dispatch<SetStateAction<ITabCombination>>
  images?: {
    bottom_left_image?: string
    top_right_image?: string
  }
  bottom_link?: {
    text: string
    url: string
  }
}

const TabbedCode = (props: ITabbedCodeProps) => {
  const {
    tabs,
    images,
    bottom_link,
    selectedTabCombination,
    setSelectedTabCombination,
  } = props

  const updateSelectedTabCombination = (
    tabSectionIndex: number,
    tabLabel: string
  ) => {
    const selectedCombinationArray = [...selectedTabCombination.combination]
    selectedCombinationArray[tabSectionIndex] = tabLabel
    const newTabCombination = tabs.combinations.find(
      (combo) => combo.combination.join() === selectedCombinationArray.join()
    )
    if (newTabCombination) setSelectedTabCombination({ ...newTabCombination })
  }

  return (
    <div className="TabbedCode" data-testid="tabbed-code">
      <div className="relative z-20 border border-grey-6 rounded-[10px]">
        {tabs.sections.map((tabSection, tabSectionIndex) => {
          return (
            <ul
              key={`tabSection-${tabSectionIndex}`}
              className="TabbedCode__tabs"
              style={{
                borderRadius: tabSectionIndex === 0 ? '10px 10px 0 0' : '',
                borderBottom:
                  tabSectionIndex < tabs.sections.length - 1
                    ? '1px solid #CFCFCF'
                    : '0px solid transparent',
              }}
              role="tablist"
            >
              {tabSection.labels.map((tabLabel, tabLabelIndex) => (
                <button
                  data-testid={`tab-${tabLabel.replaceAll(' ', '-')}`}
                  disabled={detectDisabledTabs(
                    tabs.combinations,
                    selectedTabCombination.combination
                  ).includes(tabLabel)}
                  role="tab"
                  className={`TabbedCode__tab ${
                    selectedTabCombination.combination[tabSectionIndex] ===
                    tabLabel
                      ? 'active'
                      : ''
                  }`}
                  key={`${tabLabel}-${tabLabelIndex}`}
                  onClick={() =>
                    updateSelectedTabCombination(tabSectionIndex, tabLabel)
                  }
                  aria-selected={
                    selectedTabCombination.combination[tabLabelIndex] ===
                    tabLabel
                  }
                >
                  {tabLabel}
                </button>
              ))}
            </ul>
          )
        })}
        <CodeBox selectedTabCombination={selectedTabCombination} />
        {images && (
          <>
            {images.bottom_left_image && (
              <Image
                src="https://assets.cloud.pennylane.ai/pennylane_website/pages/install/hero_1.png"
                alt=""
                className="CornerImage CornerImage__bottom-left"
                data-testid="bottom-left-install-image"
              />
            )}
            {images.top_right_image && (
              <Image
                src="https://assets.cloud.pennylane.ai/pennylane_website/pages/install/hero_2.png"
                alt=""
                className="CornerImage CornerImage__top-right"
                data-testid="top-right-install-image"
              />
            )}
          </>
        )}
      </div>
      {bottom_link && bottom_link.text && bottom_link.url && (
        <div className="w-full flex justify-center">
          <LinkComponent href={bottom_link.url} addClass="Bottom__link">
            {bottom_link.text}
            <i className="bx bx-chevron-right" />
          </LinkComponent>
        </div>
      )}
    </div>
  )
}

export default TabbedCode
