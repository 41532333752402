import Seo from '../components/seo'
import InstallHero from '../components/InstallPage/InstallHero/InstallHero'
import install from '../content/install'
import SliderSection from '../components/SliderSection/SliderSection'
import { useMemo } from 'react'
import InstallHardware from '../components/InstallPage/InstallHardware/InstallHardware'
import TripleCardSection from '../components/TripleCardSection/TripleCardSection'
import SpotlightSection from '../components/SpotlightSection/SpotlightSection'
import HighPerformanceSection from '../components/InstallPage/HighPerformanceSection/HighPerformanceSection'

const {
  meta_title,
  meta_description,
  meta_image,
  pennylane_cloud_section,
  devices_and_quantum_hardware_section,
  get_started_with_pennylane,
  spotlight_section,
  high_performance_section,
} = install

const InstallPage = () => {
  const usedByPennyLaneSlides = useMemo(() => {
    return pennylane_cloud_section.partners.map((slide) => {
      return {
        ...slide,
        logo: slide.logo,
      }
    })
  }, [pennylane_cloud_section.partners])

  return (
    <>
      <InstallHero />
      <SpotlightSection announcements={spotlight_section.announcements} />
      <SliderSection
        title={pennylane_cloud_section.title}
        subtitle={pennylane_cloud_section.subtitle}
        description={pennylane_cloud_section.description}
        slides={usedByPennyLaneSlides}
        simplePartnerCards={true}
      />
      <HighPerformanceSection
        cta_hero={high_performance_section.cta_hero}
        tabs={high_performance_section.tabs}
        bottom_link={high_performance_section.bottom_link}
      />
      <InstallHardware
        title={devices_and_quantum_hardware_section.title}
        description={devices_and_quantum_hardware_section.description}
        partners={devices_and_quantum_hardware_section.partners}
        all_plugins_link={devices_and_quantum_hardware_section.all_plugins_link}
      />
      <TripleCardSection
        title={get_started_with_pennylane.title}
        description={get_started_with_pennylane.description}
        cards={get_started_with_pennylane.cards}
        background_image={get_started_with_pennylane.background_image}
        background_colour={get_started_with_pennylane.background_colour}
      />
    </>
  )
}

export default InstallPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
