import LinkComponent from '../../LinkComponent/LinkComponent'
import Image from '../../Image/Image'
import './HardwarePartnerCard.scss'

interface IHardwarePartnerCardProps {
  partner: {
    link: string
    image: string
    fullWidthImage?: boolean
    aria_label: string
  }
}

const HardwarePartnerCard = ({
  partner: { link, image, fullWidthImage, aria_label },
}: IHardwarePartnerCardProps) => {
  return (
    <LinkComponent
      href={link}
      addClass="HardwarePartnerCard"
      testId="hardware-partner-card"
      aria-label={aria_label}
    >
      <div
        className={
          fullWidthImage
            ? 'HardwarePartnerCard__logo--wide'
            : 'HardwarePartnerCard__logo'
        }
      >
        <Image
          className="max-w-full h-full"
          src={image}
          data-testid="hardware-partner-card-image"
          alt=""
        />
      </div>
      <div className="HardwarePartnerCard__icon">
        <i className="bx bx-chevron-right"></i>
      </div>
    </LinkComponent>
  )
}

export default HardwarePartnerCard
